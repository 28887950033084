// filename: src/views/AboutUs.vue
<template>
  <div class="about-us-image mobile"></div>

  <div class="static-page">
    <div class="content">
      <div class="section-with-image">
        <div class="paragraph-group">
          <h1 class="static-page-header">O nas</h1>

          <p class="static-page-paragraph">
            <span class="first-letter">D</span>zięki serwisowi TakŻyli.pl, możesz uczcić pamięć swoich bliskich poprzez
            stworzenie dla nich Profilu Pamięci,
            który zachowa najpiękniejsze chwile ich życia.
          </p>

          <p class="static-page-paragraph">
            Profil Pamięci to niezwykłe miejsce, gdzie historie życia są utrwalane poprzez daty, zdjęcia i opisy,
            zgromadzone w jednym łatwo dostępnym miejscu. To historie, do których można wracać i przekazywać kolejnym
            pokoleniom.
          </p>
        </div>

        <div class="about-us-image"></div>
      </div>

      <div class="about-us-quote">
        <div class="logo-image-wrapper">
          <img
              alt="Logo"
              class="logo-image"
              height="80"
              src="../assets/images/logo-sign.svg"
              width="80"
          />
        </div>

        <p class="static-page-paragraph quote">
          <q class="quote-text">Ten, kto żyje w pamięci swoich bliskich, nie jest martwy, jest tylko oddalony;<br>
            martwy jest tylko ten, kto jest zapomniany.</q>
          <span class="quote-author">Immanuel Kant</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>

<style lang="scss" scoped>
.static-page {
  margin-top: 44px;
  display: flex;
  background-color: #FFFFFF;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    height: 100%;
  }

  .content {
    max-width: 1296px;
    padding: 16px;

    @media (min-width: 540px) {
      padding: 88px;
    }
  }

  .static-page-header {
    font-family: 'Source Serif 4', serif;
    margin-bottom: 24px;
    font-style: italic;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }
  }

  .static-page-paragraph {
    line-height: 150%;
    letter-spacing: 0.5px;
    font-size: 1rem;
    margin-bottom: 16px;
    text-align: justify;
    font-family: 'Source Serif 4', serif;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      letter-spacing: 0.1px;
    }
  }
}

.paragraph-group {
  .static-page-header {
    @media (min-width: 768px) {
      text-align: center;
    }
  }
}

.section-with-image {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.about-us-image {
  display: none;
  background: url('../assets/images/about-us.png') no-repeat center;
  background-size: cover;

  @media (min-width: 768px) {
    display: flex;
    width: 100%;
    max-width: 434px;
    aspect-ratio: 1 / 1;
    height: auto;
    max-height: 434px;
    align-self: center;
    margin-top: 16px;
  }

  &.mobile {
    display: flex;
    height: 100px;
    width: 100%;

    @media (min-width: 768px) {
      display: none;
    }
  }
}

.logo-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.about-us-quote {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.quote {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-family: Courgette, cursive !important;
  font-size: 1rem !important;
  position: relative;

  @media (min-width: 768px) {
    width: 500px;
    font-size: 1.5rem !important;
  }

  .quote-text {
    &::before,
    &::after {
      color: #A28546;
      font-size: 2.5rem;
      position: absolute;

      @media (min-width: 768px) {
        font-size: 4rem;
      }
    }

    &::before {
      content: '“';
      top: -8px;
      left: -16px;

      @media (min-width: 768px) {
        top: 8px;
        left: -44px;
      }
    }

    &::after {
      content: '”';
      bottom: 8px;
      right: 0;

      @media (min-width: 768px) {
        right: -8px;
      }
    }
  }

  .quote-author {
    margin-top: 8px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    color: #A28546;
  }
}

.first-letter {
  font-size: 2rem;
  font-family: 'Great Vibes', cursive;
  margin-right: 4px;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
}
</style>
