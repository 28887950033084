<template>
  <div class="dashboard content">
    <div class="container">
      <h1 class="dashboard-title">Panel użytkownika</h1>

      <p class="small-text user-email" v-if="currentUserEmail">{{ currentUserEmail }}</p>

      <v-divider class="divider"></v-divider>

      <ul class="mem-profile-list">
        <li v-for="profile in memorialProfiles" :key="profile.slot_number" class="mem-profile-item">
          <span class="mem-profile-name">
            {{ profile.firstName }} {{ profile.secondName }} {{ profile.lastName }}</span>
          (Numer profilu: {{ profile.slot_number }})
          <div class="button-container">
            <button class="edit-button" @click="editMemProfile(profile.slot_number)">Edytuj</button>
            <button class="share-button" @click="copyProfileLink(profile.slot_number)">Udostępnij</button>
            <span
                v-show="showTooltipSlotNumber && tooltipSlotNumber === profile.slot_number"
                class="tooltip"
            >Link skopiowany</span>
            <button class="profile-button" @click="redirectToProfile(profile.slot_number)">Zobacz profil</button>
          </div>
        </li>
      </ul>

      <v-divider class="divider"></v-divider>

      <v-btn class="activation-button outlined-button" @click="goToActivationPage()">
        Aktywuj profil
      </v-btn>

      <br>

      <button
          v-if="isCreateButtonEnabled"
          class="button create-button"
          @click="goToCreateMemProfile"
      >Kup nowy profil
      </button>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/interceptors/axios.config";
import {useMemorialProfileStore} from "@/stores/memorial-profile";

export default {
  name: 'DashboardPage',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchMemProfiles();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchMemProfiles();
    next();
  },
  data() {
    return {
      memorialProfiles: [],
      apiUrl: process.env.VUE_APP_API_URL, // Ensure this is set in your .env file
      showTooltipSlotNumber: false,
      tooltipSlotNumber: null,
      isCreateButtonEnabled: false,
      currentUserEmail: '',
    };
  },
  created() {
    const memorialProfileStore = useMemorialProfileStore();
    memorialProfileStore.resetProfileState();

    // Check if the 'hack' query parameter is present in the URL to enable the create button
    this.isCreateButtonEnabled = this.$route.query.hack !== undefined;
  },
  methods: {
    async fetchMemProfiles() {
      try {
        const response = await axiosInstance.get(`${this.apiUrl}dashboard/`);
        this.memorialProfiles = response.data.mem_profiles;
        this.currentUserEmail = response.data.current_user_email;
      } catch (error) {
        console.error('Error fetching memorial profiles:', error);
      }
    },
    editMemProfile(slotNumber) {
      this.$router.push({name: 'memorial-profile-edit', params: {slotNumber: slotNumber}});
    },
    goToCreateMemProfile() {
      if (!this.isCreateButtonEnabled) {
        return;
      }

      const memorialProfileStore = useMemorialProfileStore();

      memorialProfileStore.createProfile().then((response) => {
        this.$router.push({name: 'memorial-profile-edit', params: {slotNumber: response.data.slot_number}});
      });
    },
    copyProfileLink(slotNumber) {
      const link = `${window.location.origin}/profil/${slotNumber}`;

      navigator.clipboard.writeText(link).then(() => {
        this.showTooltipSlotNumber = true;
        this.tooltipSlotNumber = slotNumber;

        setTimeout(() => {
          this.showTooltipSlotNumber = false;
          this.tooltipSlotNumber = null;
        }, 2000);
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    },
    redirectToProfile(slotNumber) {
      this.$router.push({path: `/profil/${slotNumber}`});
    },
    goToActivationPage() {
      this.$router.push({name: 'profile-activation'});
    },
  }
};
</script>

<style lang="scss" scoped>
.divider {
  margin: 24px 0;
}

.dashboard {
  padding: 20px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.user-email {
  margin: 0;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
}

.dashboard-title {
  font-size: 1.5rem;
  font-family: "Source Serif 4", serif;
}

.divider {
  margin-top: 8px;
}

.mem-profile-list {
  list-style-type: none;
  padding: 0;
  margin-top: 24px;
}

.mem-profile-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.mem-profile-name {
  font-weight: bold;
}

.edit-button, .create-button {
  width: 100%;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 5px; /* Rounded edges */
  font-weight: 600;
  transition: background-color 0.3s ease;
  align-self: center;

  @media (min-width: 768px) {
    width: fit-content;
  }
}

.edit-button {
  margin: 0 5px 5px;
}

.edit-button:hover, .create-button:hover {
  background-color: #333;
}

.share-button {
  color: DeepSkyBlue;
  border: 1px solid DeepSkyBlue;
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin: 0 5px 5px;
}

.share-button:hover {
  background-color: DeepSkyBlue;
  color: white;
}

.profile-button {
  background-color: #808080; // Gray color
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin: 0 5px 5px;
}

@media (max-width: 768px) {
  .mem-profile-item {
    flex-direction: column;
    align-items: center;
  }
  .edit-button, .share-button, .profile-button {
    width: calc(100% - 20px); /* Adjust width to allow for margin */
  }
}


.profile-button:hover {
  background-color: #696969; // Slightly darker shade on hover
}

.tooltip {
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(100%);
  background-color: gray;
  color: white;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 0.7em;
}

.button-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.create-button[disabled] {
  background-color: #555;
  cursor: not-allowed;
}

.create-button[disabled] + .tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.4;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
</style>
